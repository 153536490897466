<template>
  <v-container>
    <v-row>
      <v-snackbar v-model="errors" :top="true" :color="'red'">
        <p v-html="messages_txt"></p>
      </v-snackbar>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="title">
          <!-- <h1 v-if="licence.licencie.name" >Licence de {{licence.licencie.name}} {{licence.licencie.firstname}}</h1> -->
          <h1>Licence {{this.type.replace('_',' ').charAt(0).toUpperCase() + this.type.replace('_',' ').slice(1).toLowerCase()}}</h1>
        </div>
      </v-col>
    </v-row>
    <v-row id="club" class="text-center">
      <v-col cols="12">
        <h3>Informations du Club</h3>
      </v-col>

      <v-col cols="4">
        <v-text-field readonly label="Ligue" v-model="licence.club.ligue"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field readonly label="Département" v-model="licence.club.departement"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field readonly label="Club" v-model="licence.club.club"></v-text-field>
      </v-col>
    </v-row>

    <v-row id="Adult" class="text-center">
      <v-col cols="12">
        <h3>Informations du Licencié</h3>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Numéro de licence"
              v-model="licence.licencie.num_licence"
              @change="searchByAdherent(licence.licencie.num_licence)"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-radio-group v-model="licence.licencie.civilite" row>
          <v-radio label="M." value="M."></v-radio>
          <v-radio label="Mme" value="Mme"></v-radio>
          <v-radio label="Mlle" value="Mlle"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          :class="{ 'form-group--error': $v.licence.licencie.name.$error }"
          label="Nom *"
          v-model.trim="$v.licence.licencie.name.$model"
          @change="searchByName(licence.licencie.name,licence.licencie.firstname)"
        ></v-text-field>
        <p class="error-message" v-if="$v.licence.licencie.name.$anyError">Le champ est obligatoire</p>
      </v-col>

      <v-col cols="4">
        <v-text-field label="Nom de jeune fille" v-model="licence.licencie.maiden"></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          label="Prénom *"
          v-model="$v.licence.licencie.firstname.$model"
          @change="searchByName(licence.licencie.name,licence.licencie.firstname)"
        ></v-text-field>
        <p
          class="error-message"
          v-if="$v.licence.licencie.firstname.$anyError"
        >Le champ est obligatoire</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-radio-group v-model="licence.licencie.sex" row>
          <v-radio label="Masculin" value="H"></v-radio>
          <v-radio label="Féminin" value="F"></v-radio>
        </v-radio-group>
        <!-- {{$v.licence.licencie.sex}}
        <p class="error-message" v-if="$v.licence.licencie.sex.$anyError">Le champ est obligatoire</p>-->
      </v-col>

      <v-col cols="4">
        <!-- date picker in text  -->

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="$v.licence.licencie.dateofbirth.$model"
              label="Date de naissance *"
              persistent-hint
              prepend-icon="event"
              readonly
              v-on="on"
              
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="licence.licencie.dateofbirth"
            no-title
            scrollable
            @input="menu = false"
            locale="fr"
            
          >
            <v-spacer></v-spacer>
            <!-- <v-btn text color="primary" @click="menu = false">Annuler</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(licence.licencie.dateofbirth)">OK</v-btn> -->
          </v-date-picker>
        </v-menu>
      <p
          class="error-message"
          v-if="$v.licence.licencie.dateofbirth.$anyError"
        >Le champ est obligatoire</p>
        <!-- fin du date picker -->
      </v-col>

      <v-col cols="4">
        <v-text-field label="Lieu de naissance *" v-model="$v.licence.licencie.lieu_naissance.$model"></v-text-field>
        <p
          class="error-message"
          v-if="$v.licence.licencie.lieu_naissance.$anyError"
        >Le champ est obligatoire</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-text-field label="Adresse *" v-model="$v.licence.licencie.address.$model"></v-text-field>
        <p
          class="error-message"
          v-if="$v.licence.licencie.address.$anyError"
        >Le champ est obligatoire</p>
      </v-col>

      <v-col cols="4">
        <v-text-field label="Code postal *" v-model="$v.licence.licencie.postal_code.$model"></v-text-field>
        <p class="error-message" v-if="$v.licence.licencie.postal_code.$anyError">Le champ est obligatoire</p>
      </v-col>

      <v-col cols="4">
        <v-text-field label="Ville *" v-model="$v.licence.licencie.city.$model"></v-text-field>
        <p class="error-message" v-if="$v.licence.licencie.city.$anyError">Le champ est obligatoire</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-text-field label="Nationalité *" v-model="$v.licence.licencie.nationality.$model"></v-text-field>
        <p
          class="error-message"
          v-if="$v.licence.licencie.nationality.$anyError"
        >Le champ est obligatoire</p>
      </v-col>
       <v-col cols="4">
        <v-text-field label="Téléphone *" v-model="$v.licence.licencie.phone.$model"></v-text-field>
        
        <p class="error-message" v-if="$v.licence.licencie.phone.$anyError">Le champ est obligatoire</p>
      </v-col>

      <v-col cols="4">
        <v-text-field label="Email *" v-model.lazy="licence.licencie.email" @input="delayTouch($v.licence.licencie.email)"></v-text-field>
       
        <p class="error-message" v-if="!$v.licence.licencie.email.required&&$v.licence.licencie.email.$anyError">Le champ est obligatoire</p>
        <p class="error-message" v-if="!$v.licence.licencie.email.email&&$v.licence.licencie.email.$anyError ">Merci de mettre un email valide</p>
      </v-col>
    </v-row>



    <v-row>
      <v-col cols="12">
        <!-- <h3 v-if="!licence.licencie.renouvellement">Certificat médical</h3> -->
        <h3>Certificat médical / Questionnaire relatif à l'état de santé du sportif</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
           <v-col cols="12">
              <p>Ce questionnaire de santé permet de savoir si vous devez fournir un certificat médical pour (renouveler) votre licence sportive.</p>
           </v-col>
          <v-col cols="12" :key="question.group" v-for="question in questions">
            <h3>{{question.group}}</h3>
            <v-col cols="12">
              <v-row :key="q.ref" v-for="q in question.questions">
                <v-col cols="3"> 
                  <v-radio-group
                    v-model="licence.licencie.questionnaire[q.ref]"
                    @change="questionnaireChange"
                    row
                  >
                    <v-radio label="Oui" value="oui"></v-radio>
                    <v-radio label="Non" value="non"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="9" class="text-left">{{q.title}}</v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="9" class="text-left">
            <p>Nb : Les réponses formulées relèvent de la seule responsabilité du licencié</p>
            <p>Un certificat médical doit être fourni si vous répondez "Oui" à une question</p>
          </v-col>

          <p class="error-message" v-if="questionnaire_error">Le champ est obligatoire</p>
        </v-row>
        <v-row v-if="displayFileInput">
          <v-col cols="6">
            <template>
              <v-file-input
                :rules="rules_files"
                accept="image/*, .pdf"
                label="Sélectionner votre certificat médical *"
                v-model="licence.licencie.certificat"
              ></v-file-input>
            </template>

            <p class="error-message" v-if="certificat_error">Le champ est obligatoire</p>
          </v-col>
          <v-col cols="6">
            <!-- date picker in text  -->

            <v-menu
              ref="certificat"
              v-model="certificat"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="licence.licencie.dateofcertificat"
                  label="Date du certificat *"
                   persistent-hint
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="licence.licencie.dateofcertificat" 
              no-title
               scrollable
               @input="certificat = false"
            locale="fr"
            >
                <v-spacer></v-spacer>
                <!-- <v-btn text color="primary" @click="certificat = false">Cancel</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.certificat.save(licence.licencie.dateofcertificat)"
                >OK</v-btn> -->
              </v-date-picker>
            </v-menu>

            <!-- fin du date picker -->
            <p class="error-message" v-if="date_certif_error">Le champ est obligatoire</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>INFORMATIONS COMPLEMENTAIRES</h3>
      </v-col>
      <v-col cols="12">
        <h3>Autorisations FFBad</h3>
      </v-col>
    </v-row>
    <v-row>
      
        <v-checkbox
          v-model="licence.licencie.info_utilise_ffbad"
          label="Dans l’intérêt du développement du badminton français, j’accepte que mes coordonnées puissent être utilisées par la fédération à des fins de communication (newsletters, mailing, ...)."
        ></v-checkbox>
     
    </v-row>
    <v-row>
      <v-checkbox v-model="licence.licencie.accept_fede_tiers" label ="J’accepte que la fédération transmette mes coordonnées à ses partenaires afin notamment de recevoir des offres privilégiées et sélectionnées par la fédération. La liste des partenaires est disponible sur la page :
        https://www.ffbad.org/bas-de-page/partenaires/">
      </v-checkbox>
    </v-row>
    <v-row>
      <v-checkbox
        v-model="licence.licencie.result_accessible"
        label="Je souhaite que l'on puisse accéder à ma fiche de résultats. Dans le cas contraire, j’ai conscience que ma participation à au moins une compétition officielle me fera apparaitre automatiquement dans les résultats de compétition et dans le classement de la fédération conformément au règlement général des compétitions."
      ></v-checkbox>
    </v-row>
    <v-row>
 
    <p class="v-label theme--light text-left">Dans le cadre de l’exécution de la présente prise de licence et pour la promotion de la FFBaD et du badminton, le signataire reconnait que la FFBaD et ses organes déconcentrés peuvent procéder à des captations d’image et de voix et à utiliser et diffuser, pour la promotion du badminton, les images et les voix ainsi captées, sur tous supports de communication quels qu’ils soient, à titre gratuit, et ce, pendant la durée de validité de sa licence, et pour le monde entier.</p>
        <v-checkbox
          :class="{'error-message' :$v.licence.licencie.photos_ffbad.$anyError}"
          v-model="$v.licence.licencie.photos_ffbad.$model"
          label="J’atteste avoir pris connaissance des informations me concernant sur la gestion du droit à l’image effectuée par la Fédération et ses organes déconcentrés. *"
        ></v-checkbox>
    
      
    </v-row>

    <v-row>
      <h4 class="v-label theme--light ">Garanties de l’Assurance Generali </h4> 
    </v-row>
    <v-row>
      <a class="v-label theme--light " href="https://back.inscription.lesfousduvolant-quincy.fr/uploads/FFBAD_-_Formulaire_Assurance_2020-2021.pdf" target="_blanck">Voir le document </a>
      </v-row>
      <v-row>
       
      <v-checkbox
          :class="{'error-message' :$v.licence.licencie.generali.$anyError}"
          v-model="$v.licence.licencie.generali.$model"
          label="Je soussigné, atteste avoir été informé de l’intérêt que présente la souscription d’un contrat d’assurance de personne couvrant les dommages corporels auxquels la pratique sportive peut m’exposer (art. L321-4 du code du sport). Dans ce cadre, je déclare avoir reçu, pris connaissance et compris les modalités d’assurances présentées dans la notice d’information assurance FFBAD ci-annexée, et de la possibilité de souscrire à l’une des garanties d’assurance Accident Corporel facultatives proposées avec ma licence. Le prix de l’option de base incluse dans ma licence est de 0,32€ TTC. Je peux ne pas y adhérer et le signifier par écrit auprès de mon club à l’aide du formulaire de refus mis à ma disposition (procédure obligatoire). La souscription d’une option complémentaire A ou B se fait à l’aide du bulletin d’adhésion individuelle téléchargeable depuis le site internet de la FFBAD - rubrique assurance. *"
        ></v-checkbox>

    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Autorisations Club</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-checkbox
        v-model="licence.licencie.photos_club_interne"
        label="J’autorise mon club à utiliser mon image pour promouvoir ses activités dans le cadre de ses locaux à titre gratuit et ce pendant la durée de validité de ma licence."
      ></v-checkbox>
    </v-row>
    <v-row>
      <v-checkbox
        v-model="licence.licencie.photos_club_externe"
        label="J’autorise mon club à utiliser mon image en dehors de ses locaux, sur tous supports de communication quels qu’ils soient, à titre gratuit, et ce, pendant la durée de validité de sa licence."
      ></v-checkbox>
    </v-row>
    <v-row>
      <v-checkbox
        v-model="licence.licencie.sms"
        label="J'accepte de recevoir par SMS les informations liées à la vie du club."
      ></v-checkbox>
    </v-row>

    <v-row class="text-right">
      <v-col cols="12">
        <v-btn @click="send" large color="primary">Suivant</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>   


<script>
import{mapMutations} from 'vuex'
import adherent from '../../src/api/adherents.js';
import { required, requiredUnless,sameAs,email, } from 'vuelidate/lib/validators';
const touchMap = new WeakMap()
export default {
    props:["licence",'type','backValue'],
  data: () => ({
    errors: false,
    messages_txt:"",
    certificat_error:false,
    date_certif_error:false,
    questionnaire_error:false,
     menu:false,
     certificat:false,
     displayFileInput:false,
     rules_files : [
       value => !value || value.size < 2000000 || 'La taille du fichier ne doit pas être supérieur à 2 MB!',

     ],
     questions:
       [
         {group : 'Durant les 12 derniers mois',
         questions:[
        {
          title : '1) Un membre de votre famille est-il décédé subitement d’une cause cardiaque ou inexpliquée ?',
          ref:"Q1"
        },
         {
          title : '2) Avez-vous ressenti une douleur dans la poitrine, des palpitations, un essoufflement inhabituel ou un malaise ?',
          ref:"Q2"
        },
        {
          title : '3) Avez-vous eu un épisode de respiration sifflante (asthme) ?',
          ref:"Q3"
        },
        {
          title : '4) Avez-vous eu une perte de connaissance ?',
          ref:"Q4"
        },
        {
          title : '5) Si vous avez arrêté le sport pendant 30 jours ou plus pour des raisons de santé, avez-vous repris sans l’accord d’un médecin ? ',
          ref:"Q5"
        },
        {
          title : '6) Avez-vous débuté un traitement médical de longue durée (hors contraception et désensibilisation aux allergies) ?',
          ref:"Q6"
        }       

       ]        
         
         },
         {
           group: "A ce jour",
           questions: [
             {
          title : '7) Ressentez-vous une douleur, un manque de force ou une raideur suite à un problème osseux, articulaire ou musculaire (fracture, entorse, luxation, déchirure, tendinite, etc…) survenu durant les 12 derniers mois ?',
          ref:"Q7"
        },
        {
          title : '8) Votre pratique sportive est-elle interrompue pour des raisons de santé ?',
          ref:"Q8"
        },
        {
          title : '9) Pensez-vous avoir besoin d’un avis médical pour poursuivre votre pratique sportive ?',
          ref:"Q9"
        },
           ]
         }
       ]
     
     
      
     

    
    
  }),
  validations: {
    licence: {
      licencie : {
          name : {
            required,
          },
          firstname : {
            required,
          },
          
           nationality : {
            required,
          },
           address : {
            required,
          },
         
           phone : {
            required
          },
           email : {
            required,email
          },
          
          
          photos_ffbad: {
             sameAs: sameAs( () => true ) 
    
          },
          generali : {
            sameAs: sameAs( () => true ) 
          },
          lieu_naissance : {
            required
          },
          postal_code : {
            required
          },
          city : {
            required
          },
          dateofbirth : {
            required
          }
      }
    }
          
  },

  watch:{
      //   date (val) {
      //   this.licence.licencie.dateofbirthFormatted = this.formatDate(this.licence.licencie.dateofbirth)
      // },
      back(){
        if(this.backValue == true){
                this.$vuetify.goTo(0)
        }
      }


  },

  methods: {
    
    ...mapMutations(['addLicence','minusCount']),
    destroyComponent(){
      console.log('destroy element')
      this.$destroy();
    },
    delayTouch($v) {
      console.log($v,'$v');
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    send () {
      this.errors = false;
       this.certificat_error= false;
       this.date_certif_error= false;
       this.questionnaire_error = false;
    this.$v.$touch()
      if (this.$v.$invalid ||
       ((this.displayFileInput) && this.licence.licencie.certificat == undefined )||
       ((this.displayFileInput) && (this.licence.licencie.dateofcertificat == "" || this.licence.licencie.dateofcertificat == undefined) )||
       (Object.keys(this.licence.licencie.questionnaire).length <9 )
       ) {
       console.log('erreur')
         this.$vuetify.goTo(0)
       this.errors=true;
       this.messages_txt = "Le ou les champs suivants sont incorrects :  <br/>"
       if(this.$v.licence.licencie.name.$error){
         this.messages_txt += "- Nom manquant<br/>"
       }
       if(this.$v.licence.licencie.firstname.$error){
         this.messages_txt += "- Prénom manquant<br/>"
       }
       if(this.$v.licence.licencie.dateofbirth.$error){
         this.messages_txt += "- Date de naissance manquante<br/>"
       }
       if(this.$v.licence.licencie.lieu_naissance.$error){
         this.messages_txt += "- Lieu de naissance manquant<br/>"
       }
      //  if(this.$v.licence.licencie.sex.$error){
      //    this.messages_txt += "- sex <br/>"
      //  }
       if(this.$v.licence.licencie.nationality.$error){
         this.messages_txt += "- Nationalité manquante <br/>"
       }
       if(this.$v.licence.licencie.address.$error){
         this.messages_txt += "- Adresse manquante<br/>"
       }
      if(this.$v.licence.licencie.postal_code.$error){
         this.messages_txt += "- Code postal manquant<br/>"
       }
      if(this.$v.licence.licencie.city.$error){
         this.messages_txt += "- Ville manquante<br/>"
       }      
       if(this.$v.licence.licencie.phone.$error){
         this.messages_txt += "- Téléphone manquant<br/>"
       }
      if(this.$v.licence.licencie.email.$error){
         this.messages_txt += "- Email manquant<br/>"
       }
       if(this.$v.licence.licencie.photos_ffbad.$error){
         this.messages_txt += "- Il faut autoriser l'utilisation des photos par la  FFBAD <br/>"
       }
       if(this.$v.licence.licencie.generali.$error){
         this.messages_txt += "- Il faut valider la prise de connaissance des garanties de l'assurance Generali <br/>"
       }
       

       if((this.displayFileInput) && this.licence.licencie.certificat == undefined){
        this.certificat_error= true;
    
         this.messages_txt += "- Certificat médical manquant <br/>";
       }
     
       if((this.displayFileInput) && (this.licence.licencie.dateofcertificat == "" || this.licence.licencie.dateofcertificat == undefined) ){
        this.date_certif_error= true;
         this.messages_txt += "- Date du certificat médical absente <br/>";
       }
       console.log(Object.keys(this.licence.licencie.questionnaire).length,'longueur question');
        if(Object.keys(this.licence.licencie.questionnaire).length <9 ){
          this.messages_txt += "- Certaines questions du questionnaire de santé n'ont pas eu de réponse <br/>";
          this.questionnaire_error = true
        }
       
      } 

      else{
         
             
        this.$emit("send", {'licence':this.licence,'type':this.type});   
        
      }
      
      
      












     console.log('questionnaire',this.questionnaire);
   //  this.$emit("send", {'licence':this.licence,'type':this.type});   

    },

     searchByAdherent(licence){
        this.$emit("numLicence", licence); 
        },
      searchByName(name,firstname){
        if(name !== "" && firstname !== ""){
          this.$emit("searchByName",{"name": name,'firstname':firstname}); 
        }
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-');
        console.log('foramt date',year, month, day);
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
              console.log('emit parse date');
         this.$emit("parseDate", date); 
   
      },
       questionnaireChange(){
        console.log('questionnaire change')
      let quest = this.licence.licencie.questionnaire;
       this.displayFileInput =false;
      for (const property in quest) {
        if(quest[property] == "oui"){
          this.displayFileInput =true;
        }
       
      }

      console.log(this.displayFileInput);
      
    },
       renouvellementlicence(){
          if(this.licence.licencie.renouvellement== true ){
            return 'oui';
          }else{
            return 'Non';
          }
    },
    certif_moins_trois_ans(){
          if(this.licence.licencie.renouvellement_moins_3ans== true ){
            return 'Non';
          }else{
            return 'Non';
          }
    },
    
  },
 
  computed: {
 
   
 
  },
   created () {
     console.log('created form adult')
   this.$vuetify.goTo(0)
  }

};
</script>

<style lang="css">
.v-text-field input[readonly="readonly"] {
  color: grey;
}
p.error-message {
  color: red;
  background-color: #ffffff !important;
  text-align: left;
}
div.error-message label{
  color: red!important;
}
a.v-label {
  margin-top: 15px;
}
.v-date-picker-table{
  height: 100%!important;
}
</style>